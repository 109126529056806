import { ShopSync, SyncStatus } from "#app/models";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * MUIのダイアログに onOpen が無かったのでできるようにした.
 */
type NickLoadingProps = {
	display: boolean,
	label?: string,
}

export default function NickLoading({ ...props }: NickLoadingProps) {
	

	useEffect(() => {
		// // console.log("props.open = " + props.open);
		// if ( prev_open && !props.open ) {
		// 	prev_open = false;
		// 	// console.log("閉じた！");
		// }
		// if (!prev_open && props.open) {
		// 	// 開いた！
		// 	prev_open = true;
		// 	if (onOpen) onOpen();
		// 	// console.log("開いた!");
		// }
		// ここから関数を返すと、Reactはアンマウントの直前にそれを呼び出す.
		return () => {
			// console.log("unmount! ");
		}
	});
	return (
		<Box
			sx={{
				marginTop: "3rem",

				paddingBottom: "5rem",
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: "column",
				display: props.display ? 'flex' : "none",
			}}
		>
			<CircularProgress />
			<Typography sx={{ mt: 1.5 }} variant="caption">{props.label ? props.label : "読み込み中"}</Typography>
		</Box>
	);
}
